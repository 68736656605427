
@media
(max-aspect-ratio: 4/3),
screen and (max-height: 910px),
screen\0{

    .statistiques .etiquetteHeader {
        height: 45%;
    }

    .statistiques .etiquetteBody {
        height: 55%;
    }

    .examens ul.leaders li .textQuestion:after,
    .examens ul.chalanger li .textQuestion:after{
        content: ".......................................................................................................................................................................";
    }

    .accueil .degradeLivre nav.menuTheme ul li .titre{
        font-size: 1rem;
    }


    .menuOngletVertical li {
        font-size: 4.5em;
        line-height: 113%;
    }

}

@media
(max-aspect-ratio: 16/8){
    .examens ul.leaders li .textQuestion::after, .examens ul.chalanger li .textQuestion::after {
        content: "........................................................................................................................................................................................................................................................";
    }

}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1280px)
and (orientation : portrait) {
    .examens .chiffreNumQuestion{
        font-size: 3em;
    }

    .examens ul.leaders li .textQuestion:after,
    .examens ul.chalanger li .textQuestion:after{
        content: "...............................................................................................................";
    }
}

@media only screen and (min-height: 1000px), screen\0{

    .accueil .degradeLivre nav.menuTheme ul li .lettre .stats{
        font-size:1.7rem;
        right:2.9rem;
    }

    .accueil .degradeLivre nav.menuTheme ul li .lettre {
        font-size: 4rem;
        line-height: 3rem;
    }

    .accueil .degradeLivre nav.menuTheme ul li .titre{
        font-size: 0.9rem;
    }

    .accueil .degradeLivre nav.menuTheme{
        width:20%;
        max-width: 14rem;
    }

    .accueil .etiquetteModule .etiquetteTitre .degradeHorizontal{
        font-size:1.2rem;
    }

    .partie #contenuAjax .sequences .sequence .cadre td h4{
        font-size: 1.1rem;
    }



}

@media only screen and (max-height: 900px), screen\0{

    .statistiques .colMenu table tbody tr td .picto{
        font-size: 4em;
    }

    .statistiques .les2pages{
        font-size: 0.86em;
    }

    .examens .reponse.reponseFausse ul li.reponse:not(.bonneReponse):before{
        line-height: 0.88em;
        font-size: 1.66em;
    }

    .panneaux .coteGauche .txtIntroListePanneaux p{
        font-size:0.89em;
    }

    .menuOngletVertical li {
        font-size: 3.7em;
        line-height: 113%;
    }

}

@media only screen and (max-height: 860px), screen\0{

    .statistiques .etiquetteHeader {
        height: 40%;
    }

    .statistiques .etiquetteBody {
        height: 60%;
    }

    .statistiques .colMenu table tbody tr td .picto{
        font-size: 3.7em;
    }

    .statistiques .les2pages{
        font-size: 0.8em;
    }

    .panneaux #contenuAjax .txtIntroListePanneaux td.etape{
        font-size: 2.5em;
    }

    .panneaux #contenuAjax .txtIntroListePanneaux .spacer td {
        font-size: 1.9em;
    }

    .panneaux #contenuAjax .txtIntroListePanneaux td.txtLeft {
        font-size: 0.85em;
    }

    .panneaux #contenuAjax .cartouche .zoomPanneaux,
    .panneaux #contenuAjax .cartouche .misEnSituation {
        font-size:2.8em;
    }

    .examens .reponse h4 {
        font-size: 0.9em;
    }

    .panneaux .formePanneaux .label, .panneaux .couleur .label {
        font-size: 0.75em;
    }

    .barreDegradeHaut {
        height: 0.15em;
    }

    footer nav #menuResultat .link, footer nav #menuResultat .nolink{
        font-size: 1.5rem;
    }

    .index .btnAddlevel {
        padding: 0.2% 0rem;
    }

    .accueil .degradeLivre nav.menuTheme ul li .lettre .stats{
        font-size:1.3rem;
        right:2.16rem;
    }

    .accueil .etiquetteModule .etiquetteTitre .degradeHorizontal{
        line-height: 0.95rem;
    }

    .accueil .etiquetteModule .etiquetteTitre .avancement{
        font-size: 1.5em;
        width: 14%;
    }

    .accueil .etiquetteModule .etiquetteTitre .degradeHorizontal{
        font-size:0.8rem;
    }

    .sequence .closeSequence div.contentResponsive{
        font-size: 1.9rem;
    }

    .accueil .degradeLivre nav.menuTheme ul li .lettre {
        font-size: 2.5em;
        line-height: 0.67em;
    }

    .accueil .degradeLivre nav.menuTheme ul li .titre{
        font-size: 0.8rem;
    }

    .accueil .degradeLivre nav.menuTheme{
        width:20%;
    }

    .examens #question h3 {
        font-size: 1rem;
    }

    .examens ul.leaders li .textQuestion:after,
    .examens ul.chalanger li .textQuestion:after{
        content: ".......................................................................................................................................";
    }

    .index header .sousTitre {
        font-size: 1.1rem;
        margin-left: 7.5rem !important;
    }
}

@media only screen and (max-height: 700px), screen\0{

    .menuOngletVertical li {
        font-size: 3em;
        line-height: 113%;
    }

    .statistiques .etiquetteHeader {
        height: 50%;
    }

    .statistiques .etiquetteBody {
        height: 50%;
    }

    .statistiques .colMenu table tbody tr td .picto{
        font-size: 2.9em;
    }

    .statistiques .les2pages{
        font-size: 0.75em;
    }


    .statistiques #contenuAjax h2, .statistiques #contenuAjax h3 {
        font-size:1em;
        margin:0;
        padding:0;
    }

    .panneaux #contenuAjax .cartouche .zoomPanneaux,
    .panneaux #contenuAjax .cartouche .misEnSituation {
        font-size:2.5em;
    }

    .panneaux .formePanneaux .label, .panneaux .couleur .label {
        font-size: 0.66em;
    }

    .examens .reponse ul li {
        display: block;
        font-size: 1.7rem;
        height: 1.1em;
        line-height: 1em;
        padding: 0;
        width: 1.1em;
    }
    .accueil .degradeLivre nav.menuTheme ul li .lettre {
        font-size:2rem;
        line-height: 1.6rem;
    }

    .accueil .degradeLivre nav.menuTheme ul li .titre{
        font-size: 0.75rem;
    }

    .partie #contenuAjax .sequences .sequence .cadre td h4{
        font-size: 0.8rem;
    }

    .resultats #contenuAjax .illustration {
        height: 159%;
        width: 34%;
    }
}

